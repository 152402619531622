import * as React from 'react'
import { HeroInterface } from '../interfaces/hero'
import { ServicesInterface } from '../interfaces/services'
import { AboutInterface } from '../interfaces/about'
import { WhyUsInterface } from '../interfaces/whyus'
import { TeamInterfaces } from '../interfaces/team'
import { TestmonyInterface } from '../interfaces/Testmonies'


export const HomeScreen = () => {
    return (
        <>
            <HeroInterface />
            <AboutInterface />
            <ServicesInterface />
            <WhyUsInterface />
            <TeamInterfaces />
            <TestmonyInterface />
        </>
    )
}