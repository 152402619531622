import * as React from 'react'
import { BreadcrumpComponent } from '../components/breadcrump'
import { others, services, team } from '../utils/assets'
import bg from '../assets/img/logos/atnb-with-text.png'
import { app_description } from '../utils/utils'
import { Link } from 'react-router-dom'
import { routes } from '../utils/routes'
import { FaUsers } from 'react-icons/fa6'
import { Colors } from '../styles/styles'
import { GrServices } from 'react-icons/gr'
import { FaProjectDiagram } from 'react-icons/fa'
import { TeamInterfaces } from './team'

export const AboutInterface = () => {
    return (
        <>
            {/* BreaCrump */}

            {/* --------------------------------- */}
            <div className="container-xxl py-5">
                <div className="container">
                    <div className="row g-4">
                        {services.map((service, key) => {
                            key += 1
                            const { description, icon, title, children } = service
                            return (
                                <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay="0.1s" key={(key * Math.random()).toString()}>
                                    <div className="h-100 bg-dark p-4 p-xl-5">
                                        <div className="d-flex align-items-center justify-content-between mb-4">
                                            <div className="btn-square rounded-circle" style={{ width: "64px", height: "64px", background: "#000000" }}>
                                                {/* <img className="img-fluid" src="img/icon/icon-3.png" alt="Icon" /> */}
                                                {icon}
                                            </div>
                                            <h1 className="display-1 mb-0" style={{ color: "#000000" }}>{key}</h1>
                                        </div>
                                        <h5 className="text-white">{title}</h5>
                                        <hr className="w-25" />
                                        <span>{description}</span>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>

            {/* --------------------------------- */}
            <div className="container-fluid bg-light overflow-hidden my-5 px-lg-0">
                <div className="container about px-lg-0">
                    <div className="row g-0 mx-lg-0">
                        <div className="col-lg-6 ps-lg-0" style={{
                            minHeight: "400px"
                        }}>
                            <div className="position-relative h-100 d-flex justify-content-center">
                                < img className="position-absolute img-fluid w-100- h-100" src={bg} style={{}} alt="" />
                            </div>
                        </div>
                        <div className="col-lg-6 about-text py-5 wow fadeIn" data-wow-delay="0.5s">
                            <div className="p-lg-5 pe-lg-0">
                                <div className="bg-primary mb-3 design"></div>
                                <h1 className="display-5 mb-4">A propos de nous</h1>
                                <p className="mb-4 pb-2">{app_description}</p>
                                <div className="row g-4 mb-4 pb-3">
                                    <div className="col-sm-4 wow fadeIn" data-wow-delay="0.1s">
                                        <div className="d-flex align-items-center">
                                            <div className="btn-square bg-white rounded-circle" style={{ width: "64px", height: "64px" }}>
                                                {/* <img className="img-fluid" src="img/icon/icon-1.png" alt="Icon" /> */}
                                                <FaUsers size={Colors.iconSize} color={Colors.primaryColor} />
                                            </div>
                                            <div className="ms-4">
                                                <h2 className="mb-1" data-toggle="counter-up">{others.clients}</h2>
                                                <p className="fw-medium text-primary mb-0">Clients</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-4 wow fadeIn" data-wow-delay="0.3s">
                                        <div className="d-flex align-items-center">
                                            <div className="btn-square bg-white rounded-circle" style={{ width: "64px", height: "64px" }}>
                                                {/* <img className="img-fluid" src="img/icon/icon-5.png" alt="Icon" /> */}
                                                <FaProjectDiagram size={Colors.iconSize} color={Colors.primaryColor} />
                                            </div>
                                            <div className="ms-4">
                                                <h2 className="mb-1" data-toggle="counter-up">{others.projects}</h2>
                                                <p className="fw-medium text-primary mb-0">Projects</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-sm-4 wow fadeIn" data-wow-delay="0.3s">
                                        <div className="d-flex align-items-center">
                                            <div className="btn-square bg-white rounded-circle" style={{ width: "64px", height: "64px" }}>
                                                {/* <img className="img-fluid" src="img/icon/icon-5.png" alt="Icon" /> */}
                                                <GrServices size={Colors.iconSize} color={Colors.primaryColor} />
                                            </div>
                                            <div className="ms-4">
                                                <h2 className="mb-1" data-toggle="counter-up">{others.services}</h2>
                                                <p className="fw-medium text-primary mb-0">Services</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Link to={routes['home']} className="btn btn-primary rounded-pill py-3 px-5">Voir plus</Link>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        </>
    )
}