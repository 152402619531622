import * as React from 'react'
import { BreadcrumpComponent } from '../components/breadcrump'

export const TestmonyScreen = () => {
    return (
        <>
            <BreadcrumpComponent
                route={"Témoignages"}
                routes={["Acceuil", "Pages", "Témoignages"]}
                key={902023020}
            />
        </>
    )
}