import * as React from 'react'
import { team } from '../utils/assets'
import { app_name } from '../utils/utils'

export const TeamInterfaces = () => {
    return (
        <div className="container-xxl py-5">
            <div className="container">
                <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                    <div className="bg-primary mb-3 mx-auto design"></div>
                    <h1 className="display-5 mb-5">Notre équipe</h1>
                    <p>Chez {app_name}, notre équipe est au cœur de notre succès. <br /> Composée de professionnels passionnés et talentueux, elle réunit des experts aux compétences variées qui travaillent en harmonie pour atteindre un objectif commun : la satisfaction de nos clients</p>
                </div>
                <div className="row g-4">
                    {team.map((m, key) => {
                        const { fb, istgrm, lkdn, image, fullname, id, designation, x } = m
                        return (
                            <div className="col-lg-3 col-md-6 wow fadeInUp" data-wow-delay="0.1s" key={key * Math.random()}>
                                <div className="team-item">
                                    <div className="overflow-hidden position-relative">
                                        <img className="img-fluid" src={image} alt="" />
                                        <div className="team-social">
                                            <a className="btn btn-square btn-dark rounded-circle m-1" href={fb}><i className="fab fa-facebook-f"></i></a>
                                            <a className="btn btn-square btn-dark rounded-circle m-1" href={x}><i className="fab fa-twitter"></i></a>
                                            <a className="btn btn-square btn-dark rounded-circle m-1" href={istgrm}><i className="fab fa-instagram"></i></a>
                                        </div>
                                    </div>
                                    <div className="text-center p-4">
                                        <h5 className="mb-0">{fullname}</h5>
                                        <span className="text-primary">{designation}</span>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}