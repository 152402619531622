export const app_name = "ATNB"
export const app_long_name = "All Things Nice and Beautiful"
export const app_mail = "contact@atnbservices.com"
export const app_phone = "+243 998 881 578"
export const app_phone_2 = "+243 816 394 514"
export const app_owner = "Alphonse Tsongo"
export const app_dev = "Buhendje Soft Services"
export const app_dev_url = "https://buhendje.com"
export const app_adresse = "Goma, NK, République Démocratique du Congo"
export const app_horaire = "Lundi - Vendredi : 7H20 - 22H00"
export const app_description = `est un groupe d’entreprise privée spécialisée dans l’immobilier, le
transport, le bien-être, style fashion et la chaine de formation, de présentation et de
distribution des produits de AIM GLOBAL et d’autre services de ATNB.
Elle fonctionne principalement moyennant la plateforme ATNB SERVICES ainsi que des
équipes spécialisées en MLM, Mix Marketing, E-Marketing, Copywriting, CRM, etc.`

export const app_fb = "https://facebook.com"
export const app_tlgrm = "https://web.telegram.org"
export const app_istgrm = "https://instagram.com"
export const app_lkdn = "https://linkedin.com/"
export const app_x = "https://x.com/"