import * as React from 'react'
import { BreadcrumpComponent } from '../components/breadcrump'
import { others, services, team } from '../utils/assets'
import bg from '../assets/img/logos/atnb-with-text.png'
import { app_description } from '../utils/utils'
import { Link } from 'react-router-dom'
import { routes } from '../utils/routes'
import { FaUsers } from 'react-icons/fa6'
import { Colors } from '../styles/styles'
import { GrServices } from 'react-icons/gr'
import { FaProjectDiagram } from 'react-icons/fa'
import { FooterComponent } from '../components/footer'
import { AboutInterface } from '../interfaces/about'
import { TeamInterfaces } from '../interfaces/team'
import { ServicesInterface } from '../interfaces/services'
export const AboutScreen = () => {
    return (
        <>
            {/* BreaCrump */}
            <BreadcrumpComponent
                route={"A propos de nous"}
                routes={["Acceuil", "Pages", "A propos de nous"]}
                key={909929}
            />
            {/* --------------------------------- */}
            <AboutInterface />
            <TeamInterfaces />
            <ServicesInterface />
        </>
    )
}