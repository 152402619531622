import './assets/css/bootstrap.min.css'
import './assets/css/style.css'

import "./assets/lib/animate/animate.min.css"
import "./assets/lib/lightbox/css/lightbox.min.css"
import "./assets/lib/owlcarousel/assets/owl.carousel.min.css"
import "react-responsive-carousel/lib/styles/carousel.min.css";

import { HashRouter as Router, Routes, Route } from 'react-router-dom';
import { HomeScreen } from './pages/home';
import { ServicesScreen } from './pages/services';
import { AboutScreen } from './pages/about';
import { HeaderComponent } from './components/header';
import { routes } from './utils/routes';
import { FooterComponent } from './components/footer';
import { TeamsScreen } from './pages/team';
import { TestmonyScreen } from './pages/testmony';
import { ContactsScreen } from './pages/contacts';
import { NotfoundScreen } from './pages/notfound';

function App() {
  return (
    <>
      <Router>
        <div>
          <HeaderComponent />
        </div>
        <Routes>
          <Route ex path={"/"} element={<HomeScreen />} />
          <Route path={routes['home']} element={<HomeScreen />} />
          <Route path={routes['services']} element={<ServicesScreen />} />
          <Route path={routes['about']} element={<AboutScreen />} />
          <Route path={routes['team']} element={<TeamsScreen />} />
          <Route path={routes['testmony']} element={<TestmonyScreen />} />
          <Route path={routes['contact']} element={<ContactsScreen />} />
          <Route path={"*"} element={<NotfoundScreen />} />
        </Routes>
        <div>
          <FooterComponent />
        </div>
      </Router>
    </>
  );
}

export default App;
