import * as React from 'react';
import { carroussels } from '../utils/assets';
import { Carousel } from 'react-responsive-carousel';
import { app_name } from '../utils/utils';
import { Link } from 'react-router-dom';

export const HeroInterface = () => {

    return (
        <>
            <div className="container-fluid p-0 pb-5">
                <div className="d-flex justify-content-center">
                    <Carousel
                        ariaLabel={app_name}
                        autoFocus
                        autoPlay
                        // centerMode
                        stopOnHover
                        // emulateTouch
                        infiniteLoop
                        className="w-100"
                        renderArrowPrev={(e) => {}}
                    >
                        {carroussels.map((carroussel, i) => {
                            const { description, image, key, title } = carroussel;
                            return (
                                <div key={i}>
                                    <div
                                        className="d-flex justify-content-center align-items-center"
                                        style={{
                                            backgroundImage: `url(${image})`,
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'center',
                                            width: "100vw",
                                            height: "100vh"
                                        }}
                                    >
                                        <div className="container">
                                            <div className="row justify-content-center">
                                                <div className="col-12 col-lg-8 text-center">
                                                    <h1 className="display-3 text-white animated slideInDown mb-4">
                                                        {title}
                                                    </h1>
                                                    <p className="fs-5 text-white mb-4 pb-2">
                                                        {description}
                                                    </p>
                                                    <Link to="#" className="btn btn-primary rounded-pill py-md-3 px-md-5 me-3 animated slideInLeft">
                                                        En savoir plus
                                                    </Link>
                                                    <Link to="#" className="btn btn-light rounded-pill py-md-3 px-md-5 animated slideInRight">
                                                        Nou contacter pour ce service
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </Carousel>
                </div>
            </div>
        </>
    );
}
