import * as React from 'react'
import { app_name } from '../utils/utils'
import { testmonials } from '../utils/assets'

export const TestmonyInterface = () => {
    return (
        <div className="container-xxl py-5 wow fadeInUp" data-wow-delay="0.1s">
            <div className="container">
                <div className="text-center wow fadeInUp" data-wow-delay="0.1s">
                    <div className="bg-primary mb-3 mx-auto" style={{ width: "60px", height: "2px" }}></div>
                    <h1 className="display-5 mb-5">Témoignages</h1>
                    <p>Nous croyons que la satisfaction de nos clients est notre meilleure publicité. <br /> Chez {app_name}, nous nous efforçons de fournir des solutions qui répondent aux attentes et aux besoins de chacun. Mais ne vous contentez pas de nous croire sur parole ! Découvrez ce que nos clients pensent de nous et comment nos services ont contribué à leur succès. Voici quelques témoignages qui reflètent la qualité de notre travail et l'impact positif que nous avons eu sur leurs projets.</p>
                </div>
                <div className="owl-carousel testimonial-carousel wow fadeInUp" data-wow-delay="0.1s">
                    {testmonials.map((test, i) => {
                        const { image, name, profession, message } = test
                        return (
                            <div className="testimonial-item text-center" data-dot={`<img className='img-fluid' src='${image}' alt=''>`}>
                                <p className="fs-5">{message}</p>
                                <h4>{name}</h4>
                                <span className="text-primary">{profession}</span>
                            </div>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}