import * as React from 'react'
import { BreadcrumpComponent } from '../components/breadcrump'
import { services } from '../utils/assets'
import { app_name } from '../utils/utils'

export const ServicesInterface = () => {
    return (
        <>
            <div className="container-xxl py-5">
                <div className="container">
                    <div className="text-center">
                        <div className="bg-primary mb-3 mx-auto design"></div>
                        <h1 className="display-5 mb-5">Nos services</h1>
                        <p>Chez {app_name}, nous nous engageons à offrir des services de haute qualité adaptés aux besoins de nos clients. <br /> Grâce à une équipe expérimentée et passionnée, nous proposons une gamme de services conçus pour répondre aux exigences de divers secteurs. Nos solutions sont personnalisées, innovantes et orientées vers les résultats, afin de garantir votre satisfaction et de contribuer à votre réussite.</p>
                    </div>
                    <div className="row g-0 service-row">
                        {services.map((service, key) => {
                            const { description, icon, title, children } = service;
                            return (
                                <div className="col-md-6 col-lg-4 wow fadeIn" data-wow-delay="0.1s" key={key}>
                                    <div className="service-item border h-100 p-5 position-relative">
                                        <div className="btn-square bg-light rounded-circle mb-4" style={{ width: "64px", height: "64px" }}>
                                            {/* <img className="img-fluid" src="img/icon/icon-6.png" alt="Icon" /> */}
                                            {icon}
                                        </div>
                                        <h4 className="mb-3 h6">{title}</h4>
                                        <p className="mb-4">{description}</p>
                                        <a className="btn position-absolute" href="" style={{ bottom: "20px" }}>
                                            <i className="fa fa-arrow-right text-white me-3"></i>
                                            Lire plus
                                        </a>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </>
    )
}