import * as React from 'react'

export const BreadcrumpComponent = ({ route, routes }) => {
    const [_1, _2, _3] = routes || ['', '', ''];

    return (
        <>
            <div className="container-fluid page-header py-5 mb-5">
                <div className="container py-5">
                    <h1 className="display-3 text-white mb-3 animated slideInDown">{route}</h1>
                    <nav aria-label="breadcrumb animated slideInDown">
                        <ol className="breadcrumb">
                            <li className="breadcrumb-item"><a className="text-white" href="#">{_1}</a></li>
                            <li className="breadcrumb-item"><a className="text-white" href="#">{_2}</a></li>
                            <li className="breadcrumb-item text-white active" aria-current="page">{_3}</li>
                        </ol>
                    </nav>
                </div>
            </div>
        </>
    )
}