import * as React from 'react'
import { BreadcrumpComponent } from '../components/breadcrump'
import { routes } from '../utils/routes'
import { TeamInterfaces } from '../interfaces/team'

export const TeamsScreen = () => {
    return (
        <>
            <BreadcrumpComponent
                route={"Notre équipe"}
                routes={["Acceuil", "Pages", "Notre équipe"]}
                key={9020230}
            />
            <TeamInterfaces />
        </>
    )
}