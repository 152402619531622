import * as React from 'react'
import { BreadcrumpComponent } from '../components/breadcrump'
import { services } from '../utils/assets'
import { ServicesInterface } from '../interfaces/services'

export const ServicesScreen = () => {
    return (
        <>
            <BreadcrumpComponent
                route={"Nos services"}
                routes={["Acceuil", "Pages", "Nos services"]}
                key={9303}
            />
            {/* ================ */}
            <ServicesInterface />
        </>
    )
}