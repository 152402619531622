import * as React from 'react'
import { app_adresse, app_dev, app_dev_url, app_long_name, app_mail, app_phone, app_phone_2 } from '../utils/utils'
import { Link } from 'react-router-dom'
import { services } from '../utils/assets'

export const FooterComponent = () => {

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // Pour un défilement en douceur
        });
    };

    return (
        <>
            <div className="container-fluid bg-dark text-secondary footer mt-5 py-5 wow fadeIn" data-wow-delay="0.1s">
                <div className="container py-5">
                    <div className="row g-5">
                        <div className="col-lg-3 col-md-6">
                            <h5 className="text-light mb-4">Addresse</h5>
                            <p className="mb-2"><i className="fa fa-map-marker-alt me-3"></i>{app_adresse}</p>
                            <p className="mb-2"><i className="fa fa-phone-alt me-3"></i>{app_phone}</p>
                            <p className="mb-2"><i className="fa fa-phone-alt me-3"></i>{app_phone_2}</p>
                            <p className="mb-2"><i className="fa fa-envelope me-3"></i>{app_mail}</p>
                            <div className="d-flex pt-2">
                                <a className="btn btn-square btn-outline-secondary rounded-circle me-2" href="" target='_blank'><i className="fab fa-twitter"></i></a>
                                <a className="btn btn-square btn-outline-secondary rounded-circle me-2" href="" target='_blank'><i className="fab fa-facebook-f"></i></a>
                                <a className="btn btn-square btn-outline-secondary rounded-circle me-2" href="" target='_blank'><i className="fab fa-youtube"></i></a>
                                <a className="btn btn-square btn-outline-secondary rounded-circle me-2" href="" target='_blank'><i className="fab fa-linkedin-in"></i></a>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <h5 className="text-light mb-4">Services</h5>
                            {services.map((s, i) => {
                                const { title, description } = s
                                return (
                                    <Link className="btn btn-link" to={"#"} key={(Math.random() * Math.random()).toString()}>{title}</Link>
                                )
                            })}
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <h5 className="text-light mb-4">Liens directes</h5>
                            <Link className="btn btn-link" href="">A propos de nous</Link>
                            <Link className="btn btn-link" href="">Nous contacter</Link>
                            <Link className="btn btn-link" href="">Services</Link>
                            <Link className="btn btn-link" href="">Terms & Condition</Link>
                            <Link className="btn btn-link" href="">Support clients</Link>
                        </div>
                        <div className="col-lg-3 col-md-6">
                            <h5 className="text-light mb-4">Newsletter</h5>
                            <p>Recevez regulièrement, les nouvelles de nos services</p>
                            <div className="position-relative w-100">
                                <input className="form-control bg-transparent border-secondary w-100 py-3 ps-4 pe-5" type="text" placeholder="E-mail" />
                                <button type="button" className="btn btn-primary py-2 position-absolute top-0 end-0 mt-2 me-2">Souscrire</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid py-4" style={{ background: "#000000" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-6 text-center text-md-start mb-3 mb-md-0">
                            &copy; <a className="border-bottom" href="#">{app_long_name}</a>, All Right Reserved.
                        </div>
                        <div className="col-md-6 text-center text-md-end">
                            Designed By <a className="border-bottom" target='_blank' href={app_dev_url}>{app_dev}</a>
                        </div>
                    </div>
                </div>
            </div>
            <a
                href="#"
                className="btn btn-lg btn-primary btn-lg-square rounded-circle back-to-top"
                onClick={(e) => {
                    e.preventDefault()
                    scrollToTop()
                }}
            >
                <i className="bi bi-arrow-up"></i>
            </a>
        </>
    )
}