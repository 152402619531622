import * as React from 'react'
import { app_adresse, app_fb, app_horaire, app_istgrm, app_lkdn, app_mail, app_name, app_phone, app_x } from '../utils/utils'
import { NavLink as Link } from 'react-router-dom'
import { routes } from '../utils/routes'
import logo from '../assets/img/logos/atnb-d.png';

export const HeaderComponent = () => {
    return (
        <>
            <div className="container-fluid bg-dark px-5">
                <div className="row gx-4 d-none d-lg-flex">
                    <div className="col-lg-6 text-start">
                        <div className="h-100 d-inline-flex align-items-center py-3 me-4">
                            <div className="btn-sm-square rounded-circle bg-primary me-2">
                                <small className="fa fa-map-marker-alt text-white"></small>
                            </div>
                            <small>{app_adresse}</small>
                        </div>
                        <div className="h-100 d-inline-flex align-items-center py-3">
                            <div className="btn-sm-square rounded-circle bg-primary me-2">
                                <small className="fa fa-envelope-open text-white"></small>
                            </div>
                            <small>{app_mail}</small>
                        </div>
                    </div>
                    <div className="col-lg-6 text-end">
                        <div className="h-100 d-inline-flex align-items-center py-3 me-4">
                            <div className="btn-sm-square rounded-circle bg-primary me-2">
                                <small className="fa fa-phone-alt text-white"></small>
                            </div>
                            <small>{app_phone}</small>
                        </div>
                        <div className="h-100 d-inline-flex align-items-center py-3">
                            <div className="btn-sm-square rounded-circle bg-primary me-2">
                                <small className="far fa-clock text-white"></small>
                            </div>
                            <small>{app_horaire}</small>
                        </div>
                    </div>
                </div>
            </div>
            {/* ============== NAV ============== */}
            <nav className="navbar navbar-expand-lg bg-white navbar-light sticky-top p-0 px-4 px-lg-5">
                <Link to={routes['home']} className="navbar-brand d-flex align-items-center">
                    <img src={logo} style={{ height: 34 }} />
                    <h2 className="m-0 text-primary">{app_name}</h2>
                </Link>
                <button type="button" className="navbar-toggler" data-bs-toggle="collapse" data-bs-target="#navbarCollapse">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarCollapse">
                    <div className="navbar-nav ms-auto py-4 py-lg-0">
                        <Link to={routes['home']} className={({ isActive }) => (isActive ? 'active nav-item nav-link' : 'nav-item nav-link')}>Acceuil</Link>
                        <Link to={routes['services']} className={({ isActive }) => (isActive ? 'active nav-item nav-link' : 'nav-item nav-link')}>Services</Link>
                        <Link to={routes['project']} className={({ isActive }) => (isActive ? 'active nav-item nav-link' : 'nav-item nav-link')}>Projets</Link>
                        <Link to={routes['about']} className={({ isActive }) => (isActive ? 'active nav-item nav-link' : 'nav-item nav-link')}>A propos de nous</Link>
                        <Link to={routes['contact']} className={({ isActive }) => (isActive ? 'active nav-item nav-link' : 'nav-item nav-link')}>Contactes</Link>
                        <div className="nav-item dropdown">
                            <a href="#" className="nav-link dropdown-toggle" data-bs-toggle="dropdown">Autres</a>
                            <div className="dropdown-menu rounded-0 rounded-bottom m-0">
                                <Link to={routes['services']} className="dropdown-item">Immobiliers</Link>
                                <Link to={routes['']} className="dropdown-item">Transports</Link>
                                <Link to={routes['team']} className="dropdown-item">Bien être</Link>
                                <Link to={routes['testmony']} className="dropdown-item">Fashions</Link>
                                <Link to={routes['testmony']} className="dropdown-item">Chaîne de formation</Link>
                                {/* <a href="404.html" className="dropdown-item">404 Page</a> */}
                            </div>
                        </div>
                    </div>
                    <div className="h-100 d-lg-inline-flex align-items-center d-none">
                        <Link className="btn btn-square rounded-circle bg-light text-primary me-2" to={app_fb}><i className="fab fa-facebook-f"></i></Link>
                        <Link className="btn btn-square rounded-circle bg-light text-primary me-2" to={app_x}><i className="fab fa-twitter"></i></Link>
                        <Link className="btn btn-square rounded-circle bg-light text-primary me-2" to={app_lkdn}><i className="fab fa-linkedin-in"></i></Link>
                        <Link className="btn btn-square rounded-circle bg-light text-primary me-0" to={app_istgrm}><i className="fab fa-instagram"></i></Link>
                    </div>
                </div>
            </nav>
        </>
    )
}