import * as React from 'react'
import { BreadcrumpComponent } from '../components/breadcrump'
import { Link } from 'react-router-dom'
import { routes } from '../utils/routes'

export const NotfoundScreen = () => {
    return (
        <>
            <BreadcrumpComponent
                route={"404 Page non trouvée"}
                key={892}
                routes={['Acceuil', 'Pages', 'Page non trouvée']}
            />
            {/* ----------------------- */}
            <div className="container-xxl py-5 wow fadeInUp" data-wow-delay="0.1s">
                <div className="container text-center">
                    <div className="row justify-content-center">
                        <div className="col-lg-6">
                            <i className="bi bi-exclamation-triangle display-1 text-primary"></i>
                            <h1 className="display-1">404</h1>
                            <h1 className="mb-4">Page Not Found</h1>
                            <p className="mb-4">Nous sommes désolés, la page que vous recherchez n'existe pas sur notre site ! Vous pouvez peut-être vous rendre sur notre page d'accueil ou essayer d'utiliser une recherche ?</p>
                            <Link className="btn btn-primary rounded-pill py-3 px-5" to={routes['home']}>Retour sur la page d'acceuil</Link>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}