import * as React from 'react'
import { app_name } from '../utils/utils'
import bg from '../assets/img/logos/atnb-with-text.png'
import { RiEqualizer2Fill } from 'react-icons/ri'
import { FaProductHunt } from 'react-icons/fa6'

export const WhyUsInterface = () => {
    return (
        <div className="container-fluid bg-light overflow-hidden px-lg-0">
            <div className="container feature px-lg-0">
                <div className="row g-0 mx-lg-0">
                    <div className="col-lg-6 feature-text py-5 wow fadeIn" data-wow-delay="0.5s">
                        <div className="p-lg-5 ps-lg-0">
                            <div className="bg-primary mb-3 design"></div>
                            <h1 className="display-5 mb-5">Pourquoi nous choisir ?</h1>
                            <p className="mb-4 pb-2">Pourquoi vous devez choisir {app_name} ?</p>
                            <p>Chez {app_name}, votre solution complète pour des services de haute
                                qualité, des produits innovants, et une formation professionnelle dans divers
                                secteurs. Découvrez tout ce que nous avons à offrir pour améliorer votre
                                bien-être, vos compétences professionnelles, et répondre à vos besoins
                                quotidiens</p>
                            <div className="row g-4">
                                <div className="col-6">
                                    <div className="d-flex align-items-center">
                                        <div className="btn-square bg-white rounded-circle" style={{ width: "64px", height: "64px" }}>
                                            {/* <img className="img-fluid" src="img/icon/icon-7.png" alt="Icon" /> */}
                                            <RiEqualizer2Fill size={20} className='img-fluid' />
                                        </div>
                                        <div className="ms-4">
                                            <p className="text-primary mb-2">Solutions Complètes</p>
                                            <h5 className="mb-0">Solutions</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="d-flex align-items-center">
                                        <div className="btn-square bg-white rounded-circle" style={{ width: "64px", height: "64px" }}>
                                            {/* <img className="img-fluid" src="img/icon/icon-10.png" alt="Icon" /> */}
                                            <RiEqualizer2Fill size={20} className='img-fluid' />
                                        </div>
                                        <div className="ms-4">
                                            <p className="text-primary mb-2">Qualité de Service</p>
                                            <h5 className="mb-0">Qualité</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="d-flex align-items-center">
                                        <div className="btn-square bg-white rounded-circle" style={{ width: "64px", height: "64px" }}>
                                            {/* <img className="img-fluid" src="img/icon/icon-3.png" alt="Icon" /> */}
                                            <RiEqualizer2Fill size={20} className='img-fluid' />
                                        </div>
                                        <div className="ms-4">
                                            <p className="text-primary mb-2">Innovation</p>
                                            <h5 className="mb-0">Produits</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="d-flex align-items-center">
                                        <div className="btn-square bg-white rounded-circle" style={{ width: "64px", height: "64px" }}>
                                            {/* <img className="img-fluid" src="img/icon/icon-2.png" alt="Icon" /> */}
                                            <RiEqualizer2Fill size={20} className='img-fluid' />
                                        </div>
                                        <div className="ms-4">
                                            <p className="text-primary mb-2">Développement Professionnel</p>
                                            <h5 className="mb-0">Services</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6 pe-lg-0" style={{ minHeight: "400px" }}>
                        <div className="position-relative h-100 d-flex justify-content-center">
                            <img className="position-absolute img-fluid w-100- h-100" src={bg} style={{}} alt="" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}