import { BsBuildings } from "react-icons/bs";
import { FaShirt } from "react-icons/fa6";
import { GrServices } from "react-icons/gr";
import { MdOutlineEmojiTransportation } from "react-icons/md";
import { TbGymnastics } from "react-icons/tb";
import { VscUngroupByRefType } from "react-icons/vsc";
import { Colors } from "../styles/styles";

import car_1 from '../assets/img/carousel-1.jpg';
import car_2 from '../assets/img/carousel-2.jpg';
import car_3 from '../assets/img/carousel-3.jpg';

import team_1 from '../assets/img/team-1.jpg'
import team_2 from '../assets/img/team-2.jpg'

export const services = [
    {
        title: "ATNB MLM Business",
        description: "Nous soutenons les entrepreneurs et ceux qui souhaitent réussir dans lemarketing de réseau. Nos services comprennent",
        icon: <VscUngroupByRefType size={Colors.iconSize} />,
        children: [
            {
                key: 1,
                title: "Rising Stars Empire",
                children: [
                    "Présentation du Business",
                    "Produits Miraculeux",
                    "Formation MLM",
                    "Evénements de l’équipe"
                ]
            },
            {
                key: 2,
                title: "Rising Stars Magazine",
                children: []
            }
        ]
    },
    {
        title: "ATNB Multiservices",
        description: "Nos services diversifiés vous accompagnent dans vos projets professionnels",
        icon: <GrServices size={Colors.iconSize} />,
        children: [
            {
                key: 1,
                title: "Service de Vente des pièces mécaniques et activités de garage véhicules",
                children: []
            },
            {
                key: 2,
                title: "Centre de formations professionnelles et Cabinet de gestion des projets",
                children: []
            },
            {
                key: 3,
                title: "Service de conception des plans architecturaux et exécutions des travaux de Génie civil",
                children: []
            },
            {
                key: 4,
                title: "Service de la nouvelle technologie de l’information et de la communication",
                children: []
            },
            {
                key: 5,
                title: "Service de parfumerie et cosmétiques",
                children: []
            },
            {
                key: 6,
                title: "Service de marketing, Organisation évènementielle et décoration professionnelle",
                children: []
            },
            {
                key: 7,
                title: "Service traiteur professionnel",
                children: []
            },
            {
                key: 7,
                title: "Service des œuvres caritatives",
                children: []
            }
        ]
    },
    {
        title: "ATNB Bien être",
        description: "Offrez-vous des moments de bien-être avec nos installations modernes",
        icon: <TbGymnastics size={Colors.iconSize} />,
        children: [
            {
                key: 1,
                title: "Salle de sports",
                children: []
            },
            {
                key: 2,
                title: "Chambre de vapeur (SAUNA) et le STEAM",
                children: []
            },
            {
                key: 3,
                title: "Salle de massage",
                children: []
            }
        ]
    },
    {
        title: "ATNB Fashion et style",
        description: "Découvrez notre ligne de vêtements tendance et nos services de couture sur mesure",
        icon: <FaShirt size={Colors.iconSize} />,
        children: [
            {
                key: 1,
                title: "Marque de vêtement ATNB (All Things Nice and Beautiful)",
                children: []
            },
            {
                key: 2,
                title: "Boutique mannequinat marque ATNB",
                children: []
            },
            {
                key: 3,
                title: "Exécuter les travaux d’atelier de couture",
                children: []
            }
        ]
    },
    {
        title: "ATNB Transport",
        description: "Nous proposons une gamme de produits de santé et bien-être pour améliorer votre vitalité et votre qualité de vie",
        icon: <MdOutlineEmojiTransportation size={Colors.iconSize} />,
        children: [
            {
                key: 1,
                title: "Transport privé et Location de véhicules",
                children: []
            },
            {
                key: 2,
                title: "Marketing des partenaires qui vendent les véhicules",
                children: []
            },
            {
                key: 3,
                title: "Expéditions des articles",
                children: []
            }
        ]
    },
    {
        title: "ATNB Immobilier",
        description: "Un magazine dédié aux parcours inspirants de succès dans le marketing de réseau et l'entrepreneuriat, offrant des tendances et des innovations à découvrir",
        icon: <BsBuildings size={Colors.iconSize} />,
        children: [
            {
                key: 1,
                title: "ATNB Hôtel -Restaurant",
                children: []
            },
            {
                key: 2,
                title: "ATNB Tourisme et restaurant",
                children: []
            },
            {
                key: 3,
                title: "ATNB appart complex",
                children: []
            }
        ]
    },
];

export const others = {
    clients: 3210,
    projects: 78,
    services: services.length
};

export const team = [
    {
        id: 1,
        fullname: "Alphonse Tsongo",
        designation: "DG de l'entreprise",
        image: team_1,
        fb: "https://facebook.com",
        lkdn: "https://linkdin.com",
        istgrm: "https://instagram.com",
        x: "https://x.com"
    },
    {
        id: 1,
        fullname: "Test 1",
        designation: "Membre de l'equipe",
        image: team_1,
        fb: "https://facebook.com",
        lkdn: "https://linkdin.com",
        istgrm: "https://instagram.com"
    },
    {
        id: 1,
        fullname: "Test 2",
        designation: "Membre de l'equipe",
        image: team_1,
        fb: "https://facebook.com",
        lkdn: "https://linkdin.com",
        istgrm: "https://instagram.com"
    },
    {
        id: 1,
        fullname: "Test 3",
        designation: "Test",
        image: team_1,
        fb: "https://facebook.com",
        lkdn: "https://linkdin.com",
        istgrm: "https://instagram.com"
    },
    // {
    //     id: 1,
    //     fullname: "Test 4",
    //     designation: "Test",
    //     image: team_1,
    //     fb: "https://facebook.com",
    //     lkdn: "https://linkdin.com",
    //     istgrm: "https://instagram.com"
    // }
];

export const carroussels = [
    {
        key: 1,
        title: "ATNB MLM Business",
        description: "une plateforme orientée mlm business et services business.",
        image: car_1
    },
    {
        key: 2,
        title: "ATNB Multiservices",
        description: "Pour faciliter l’interaction entre fournisseurs et consommateurs",
        image: car_2
    },
    {
        key: 3,
        title: "ATNB Bien être",
        description: "Pour la promotion de la mine d’or de aim global, des produits africains, des marques africaines, des consommateurs africains",
        image: car_3
    },
    // {
    //     key: 3,
    //     title: "ATNB Fashion et Styles",
    //     description: "Expérimentez votre succès en business avec Atnb Services",
    //     image: car_3
    // },
    // {
    //     key: 4,
    //     title: "ATNB Transport",
    //     description: "Un écosystème virtuel qui fédère une multitude de reseauteurs pour le bénéfice de tous en business",
    //     image: car_3
    // }
];

export const testmonials = [
    // {
    //     name: "David Maene",
    //     message: `Ceci vient de la part d'une fille
    //             Qui croit encore qu'il y a quelqu'un dans le monde
    //             Pour me faire atteindre le meilleur et le pire
    //             Je ne baisserai pas les bras même quand ça fera mal
    //             Alors, je vais tenter ma chance
    //             Je vais tenter ma chance, bébé
    //             Je vais tenter ma chance avec toi`,
    //     image: team_2,
    //     profession: "Software Developer"
    // }
]